import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import './assets/styles/index.css';

import { Provider } from 'react-redux';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { AuthProvider } from './contexts/JwtAuthContext';
import { DrawerProvider } from './contexts/DrawerContext';
import { ModalProvider } from './contexts/ModalDialogContext';
import { store } from './store/store';

const container: any = document.getElementById('root');
const root = createRoot(container);

root.render(
  <HelmetProvider>
    <AuthProvider>
      <SidebarProvider>
        <Provider store={store}>
          <BrowserRouter>
            <DrawerProvider>
              <ModalProvider>
                <App />
              </ModalProvider>
            </DrawerProvider>
          </BrowserRouter>
        </Provider>
      </SidebarProvider>
    </AuthProvider>
  </HelmetProvider>
);

serviceWorker.unregister();
